<!-- <div class="info-message">
  <span>
    Od 21.12.2023 do 2.1.2024 bude obchod uzavřen. Přijaté objednávky začneme expedovat od 2.1.2024. Děkujeme.
  </span>
</div> -->

<app-header />
<app-menu />

<div class="app-content container">
  <router-outlet />
</div>

<div class="app-footer">
  <div class="container">
    <div class="row">
      @for (category of categories(); track category.url) {
        <div class="col-md-3">
          <b class="d-block"
            ><a [routerLink]="['/i/obsah', category.url]">{{ category.label }}</a></b
          >
          @for (article of category.articles; track article._id) {
            <a class="d-block" [routerLink]="['/i/obsah', category.url, article.url]">{{ article.title }}</a>
          }
        </div>
      }
      <div class="col-md-3">
        <div>
          <b><a routerLink="/i/kontakty">Kontakty</a></b>
        </div>
        <div>
          <a href="mailto:{{ contactEmail() }}">{{ contactEmail() }}</a>
          <br />
          tel: +420 721 659 236
          <br />
          tel: +420 602 954 821
          <br />
          IČO: 12695661
          <br />
          DIČ: CZ5606122533
        </div>
      </div>
    </div>
    <hr />
    <p>
      Tyto internetové stránky používají soubory cookie. Více informací
      <a routerLink="/i/obsah/vse-o-nakupu/ochrana-osobnich-udaju">zde</a>
      .
    </p>
    <p>
      Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku.
      <br />
      Zároveň je povinen zaevidovat přijatou tržbu u správce daně on-line; v případě technického výpadku pak nejpozději
      do 48 hodin.
    </p>
    <p style="margin-bottom: 0">&copy; BEROP.CZ | Všechna práva vyhrazena.</p>
  </div>
</div>

<shared-page-loader />

<div class="float-top" [hidden]="hideFloatTop()">
  <button mat-mini-fab color="primary" (click)="goTop()">
    <icon icon="arrow_upward" />
  </button>
</div>
